ul.Social-Header {
	margin: 0;
	padding: 1rem 0 0 0;
	text-align: center;
	li {
		display: inline-block;
		a {
			color: $gray;
			margin-left: 0.5rem;
			font-size: 2rem;
			opacity: 1;
			&:hover {
				color: $pink;
			}
		}
	}
	@include breakpoint (medium large) {
		margin: 0;
		padding-top: 0;
		text-align: right;
		li {
			a {
				margin-left: 2rem;
				font-size: 1.2rem;
			}
		}
	}
}
