/*
|--------------------------------------------------------------------------
| Gallery Modals Various content
|--------------------------------------------------------------------------
*/

.Main--Gallery {
    position: relative;
    width: 100%;
    overflow: visible;
    .Gallery--Category {
        .slick-slide img {
            max-width: 100%;
            margin-top: 50%;
            margin-left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .newdirectional {
        i {
            position: absolute;
            top: 20%;
            color: white;
            font-size: 5rem;
            font-weight: 300;
            cursor: pointer;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
        }
        i.lefticon {
            left: -10%;
        }
        i.righticon {
            right: -10%;
        }
    }
}

.modaal-gallery-next {
    margin-left: 0;
    right: -40px;
    @include breakpoint(medium large) {
        display: block !important;
        right: auto;
    }
}

.modaal-gallery-prev {
    margin-right: 0;
    left: -40px;
    @include breakpoint(medium large) {
        display: block !important;
        left: auto;
    }
}

.modaal-gallery-control {
    height: 100%;
    border-radius: 0;
    width: 40px;
    bottom: 0px;
    &:hover,
    &:focus {
        background-color: transparent;
        &:before,
        &:after {
            background-color: $pink;
        }
    }
    &:before,
    &:after {
        top: 50%;
        background-color: white;
    }
    @include breakpoint(medium large) {
        width: 80px;
        bottom: 20px;
        &:before,
        &:after {
            left: 40px;
        }
    }
}

.modaal-image {
    .modaal-inner-wrapper {
        padding-left: 60px;
        padding-right: 60px;
        @include breakpoint(medium large) {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}

.pleasewait {
    display: none;
    opacity: 0.95;
    h2 {
        text-align: center;
        margin-top: 20%;
    }
}

#pleasewait {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    padding-top: 20%;
    p {
        color: $white;
    }
    h2 {
        font-size: 3em;
        color: $white;
    }
}

.callout {
    background-color: $pink;
    padding: 1rem 4rem;
    ul {
        font-size: 1.2rem;
        font-weight: 600;
    }
    h4 {
        color: white;
        text-align: center;
    }
    &.alert {
        ul {
            li {
                color: $gray-text;
            }
        }
    }
}

h4 {
    &.text-success {
        font-size: 2rem;
        padding: 1rem;
        margin: 1rem 0 0 0;
        color: green;
    }
    &.text-danger {
        padding: 1rem;
        margin: 1rem 0 0 0;
        color: red;
        font-size: 2rem;
    }
}

.relative {
    position: relative;
}

.np {
    padding: 0;
}

a#toTop {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: $pink;
    color: white;
    border-radius: 5px;
    border: 2px solid transparent;
    padding: 1rem;
    display: none;
    z-index: 3000;
    cursor: pointer;
}

#msgSubmit {
    color: green;
    text-align: center;
}

.u__white {
    color: white;
}

hr.pink-line {
    position: relative;
    display: inline-grid;
    width: 100%;
    height: 1px;
    margin: 1rem 0;
    background-color: $pink;
    border: none;
    max-width: 100%;
}

.full {
    max-width: 100%;
}

.img-zoom {
    max-width: 100%;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 1s ease-out;
    -webkit-transition: all 1s ease-out;
    &:hover {
        -ms-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        transition: all 4.5s ease-out;
        -webkit-transition: all 4.5s ease-out;
    }
}

.section-shadow {
    -webkit-box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
    -moz-box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
    box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
}

.gallery-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
}
