/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/

[v-cloak] {
	display: none;
}

html {
	font-size: 16px;
}

body {
	position: relative;
	font-family: $font-halant;
	font-style: normal;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	width: 100%;
	height: 100%;
}

a {
	display: inline-block;
	color: white;
	font-weight: 400;
	&:focus,
	&:hover {
		color: $pink;
	}
}

p {
	text-align: justify;
	color: $gray-dark;
	line-height: 1.2;
	font-weight: 400;
	font-family: $font-open;
}

h1,
h2,
h3 {
	font-family: $font-halant;
	font-size: 2.2rem;
	color: $gray-medium;
	text-transform: uppercase;
	text-align: center;
	font-weight: 400;
}

h1.Header,
h2.Header {
	margin-top: 2rem;
}

span.Cws {
	font-family: $font-halant;
	font-size: 2rem;
	color: $gray-medium;
	text-transform: uppercase;
	text-align: center;
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: block;
	line-height: 1.5rem;
	small {
		font-family: $font-novecento;
		font-size: 0.7rem;
		color: $pink;
		display: block;
		line-height: 1
	}
	@include breakpoint (medium large) {
		margin: 0;
		font-size: 2.7rem;
		line-height: 1.2rem;
	}
}

span.Cws-Footer {
	@extend span.Cws;
	font-size: 2.3rem;
	color: white;
	small {
		font-size: 0.6rem;
		color: white;
	}
}

hr.BigSeparator {
	border: none;
	margin: 3.125rem;
	box-shadow: none;
	width: 100%;
	display: inline-block;
	clear: both;
}

hr.Invisible {
	@extend hr.BigSeparator;
	background-color: transparent;
}

hr.Invisible-Small {
	@extend hr.BigSeparator;
	background-color: transparent;
	margin: 1rem;
}

hr.Invisible-ExSmall {
	@extend hr.BigSeparator;
	background-color: transparent;
	margin: 1rem;
}

ul.sitemap {
	list-style: none;
	li {
		a {
			color: $gray-dark;
		}
	}
}

img.Head {
	position: relative;
	left: 50%;
	transform: translatex(-50%);
	margin-bottom: 2.3rem;
	width: 79px;
	height: 6px;
}

.Bg {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: white;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
}

.np {
	padding: 0;
	img {
		width: 100%;
	}
}

.relative {
	position: relative;
}

.modaal-container {
	background: none;
}

.modaal-content-container {
	padding: 0 10px;
	margin-left: 0;
	transform: translateX(0);
	@include breakpoint (medium large) {
		padding: 0;
		margin-left: 50%;
		transform: translateX(-50%);
	}
}

.modaal-close {
	background-color: $pink !important;
	&:focus,
	&:hover {
		outline: none;
		background: none;
		&:before,
		&:after {
			background: #eabfd3;
		}
	}
	@include breakpoint(large) {
		background-color: transparent !important;
	}
}

.Size-Icons {
	@include breakpoint(medium large) {
		max-width: 50%;
		max-height: 50%;
	}
}

.Flex {
	display: block;
	@include breakpoint(medium large) {
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
}

.Height {
	@include breakpoint(medium large) {}
}

.padding-header {
	padding-top: 1rem;
	padding-bottom: 0;
	@include breakpoint (medium large) {
		padding-top: 1.8rem;
		padding-bottom: 0.875rem;
	}
}

.align-captacha {
	padding-left: 0.9375rem;
	margin-top: 1rem;
}
