.Top-Footer {
	position: relative;
	background-color: $gray-medium;
	border-bottom: 1px solid $pink;
	height: 0.4rem;
	margin: 0;
}

footer {
	position: relative;
	background-color: #868686;
	text-align: center;
	padding: 4rem 0;
	p {
		text-align: center;
		line-height: 1;
		color: white;
		font-family: $font-open;
		font-size: 0.875rem;
		margin-bottom: 0.5rem;
	}
	@include breakpoint(medium large) {
		a.Send {
			margin: 0;
		}
		p {
			text-align: right;
		}
	}

	a.footer-link {
		width: 100%;
	}
}

section.Footer-Copy {
	background-color: #6D6E71;
	padding: 1rem 5rem;
	text-align: center;
	overflow: hidden;
	.content-left {
		float: none;
	}
	.content-right {
		float: none;		
	}
	p {
		font-family: $font-open;
		font-size: 0.75rem;
		color: $gray;
		margin: 0 0 1rem 0;
    	text-align: center;
    	display: block;
    	@include breakpoint(medium large) {
    		display: inline-block;
    		margin-bottom: 0;
    	}
	}
	p.block {
		display: block;
		margin: 1rem 0;
		text-align: center;
	}
	a {
		display: inline-block;
		margin-left: 1rem;
	}

	a.sitemap {
		font-weight: 300;
		color: #C5C5C5;
	}

	@include breakpoint(medium large) {
		.content-left {
			float: left;
		}
		.content-right {
			float: right;		
		}
		a {
			margin-left: 2rem;
		}
	}
}
