// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group grid
////

/// Reposition a column.
///
/// @param {Number|Keyword} $position - Direction and amount to move. The column will move equal to the width of the column count specified. A positive number will push the column to the right, while a negative number will pull it to the left. Set to center to center the column.
@mixin grid-column-position($position) {
  @if type-of($position) == 'number' {
    $offset: percentage($position / $grid-column-count);

    position: relative;
    #{$global-left}: $offset;
  }
  @else if $position == center {
    &, &:last-child:not(:first-child) {
      float: none;
      clear: both;
    }
    margin-right: auto;
    margin-left: auto;
  }
  @else {
    @warn 'Wrong syntax for grid-column-position(). Enter a positive or negative number, or center.';
  }
}

/// Reset a position definition.
@mixin grid-column-unposition {
  position: static;
  float: $global-left;
  margin-right: 0;
  margin-left: 0;
}

/// Offsets a column to the right by `$n` columns.
/// @param {Number|List} $n - Width to offset by. You can pass in any value accepted by the `grid-column()` mixin, such as `6`, `50%`, or `1 of 2`.
@mixin grid-column-offset($n) {
  margin-#{$global-left}: grid-column($n);
}

/// Disable the default behavior of the last column in a row aligning to the opposite edge.
@mixin grid-column-end {
  // This extra specificity is required for the property to be applied
  &:last-child:last-child {
    float: $global-left;
  }
}

/// Shorthand for `grid-column-position()`.
/// @alias grid-column-position
@mixin grid-col-pos($position) {
  @include grid-column-position($position);
}

/// Shorthand for `grid-column-unposition()`.
/// @alias grid-column-unposition
@mixin grid-col-unpos {
  @include grid-column-unposition;
}

/// Shorthand for `grid-column-offset()`.
/// @alias grid-column-offset
@mixin grid-col-off($n) {
  @include grid-column-offset($n);
}

/// Shorthand for `grid-column-end()`.
/// @alias grid-column-end
@mixin grid-col-end {
  @include grid-column-end;
}
