/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/

$pink: #eabfd3;
$pink-light: #F5DFEA;
$gray: #C5C5C5;
$gray-medium: #A6A8AB;
$gray-dark: #808080;
$inquire: #F26D7A;
$gray-text: #8C8E91;
