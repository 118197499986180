header {
	.Top-Header {
		padding: 1rem 0;
		background-color: white;
		color: $gray-dark;
		position: relative;
		padding-left: 1rem;
		padding-right: 1rem;
		p {
			font-size: 1rem;
			text-align: left;
			margin-bottom: 0;
			line-height: 1.1rem;
			color: $gray-dark;
			small {
				font-size: 1.125rem;
			}
			a {
				color: $gray-dark;
			}
		}
		p.links-contact {
			text-align: center;
			span {
				font-size: 0.875rem;
			}
			@include breakpoint(medium large) {
				text-align: left;
			}
		}
		img.Logo {
			max-height: 85px;
		}
	}
	nav {
		background-color: $pink;
		color: white;
		margin: 0;
		position: relative;
		height: auto;
		overflow: hidden;
		width: 100%;
		ul#theMenu.menu {
			list-style: none;
			margin: 0;
			display: block;
			li {
				position: relative;
				padding: 18px 0 10px 0;
				display: block;
				@media (min-width: 640px) {
					display: inline-block;
				}
				a {
					color: white;
					display: block;
					font-family: $font-halant;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 1px;
					line-height: inherit;
					padding: 0 5px;
					text-align: center;
					text-transform: uppercase;
					transition: all 0.8s;
					&:hover {
						color: $gray-dark;
						text-shadow: none;
					}
					@media (min-width: 951px) {
						padding: 0 1.0625rem;
						font-size: 1.125rem;
					}
					@media (min-width: 720px) and (max-width: 950px) {
						padding: 0 8px;
					}
				}
				&:after {
					content: '';
					width: 4px;
					height: 4px;
					font-family: 'FontAwesome';
					position: absolute;
					color: white;
					top: 25px;
					left: -2px;
					font-size: 4px;
					@media (min-width: 640px) {
						content: '\f111';
					}
					@media (min-width: 951px) {
						top: 26px;
						width: 4px;
						height: 4px;
						font-size: 6px;
						left: -4px;
					}
				}
				&:first-child:after {
					content: '';
				}
				&.activa {
					a {
						color: $gray-dark;
						text-shadow: none;
					}
				}
			}
		}
	}
	span.Menu-Bottom {
		display: none;
		background-color: $pink;
		border-top: 1px solid darken($gray, 5%);
		height: 5px;
		margin: 0 auto;
		padding-bottom: 5px;
		@include breakpoint (medium large) {
			display: block;
		}
	}
}

div#DesktopMenu {
	display: none;
	@include breakpoint(medium large) {
		display: block;
	}
}

#Hamburguer {
	cursor: pointer;
	margin: 1rem auto;
	text-align: center;
	color: $pink;
	@include breakpoint (medium large) {
		display: none;
	}
}
