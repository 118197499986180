/*
|--------------------------------------------------------------------------
| Slider
|--------------------------------------------------------------------------
*/

#Slider-Home {
	margin-bottom: 0;
	div {
		position: relative;
		overflow: hidden;
		img {
			max-width: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			height: auto;
		}
		.bg-shadowSlide {
			position: absolute;
			display: block;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1000;
			background-color: rgba(0, 0, 0, 0.1);
		}
		.Slider-Text {
			position: relative;
			display: none;
			z-index: 1001;
			height: 100px;
			top: 40%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: transparent;
			.Legend {
				border: 2px solid white;
				padding: 5px;
				p {
					color: white;
					text-align: center;
					font-family: $font-open;
					font-size: 0.5rem;
					margin-bottom: 0;
					position: relative;
					width: 100%;
					text-transform: uppercase;
					&:before,
					&:after {
						content: "";
						display: inline-block;
						height: 0.5em;
						vertical-align: center;
						width: 15%;
						margin-right: 10px;
						margin-left: 10px;
						border-top: 1px solid white;
					}
				}
				h3 {
					span {
						font-size: 4rem;
						letter-spacing: 8px;
					}
					color: white;
					font-size: 0.625rem;
					margin-left: 1.875rem;
					margin-right: 1.875rem;
					margin-bottom: 0;
					line-height: 1;
				}
			}
			@include breakpoint(medium) {
				position: absolute;
				display: block;
				.Legend {
					p {
						font-size: 0.825rem;
					}
					h3 {
						font-size: 1.3rem;
					}
				}
			}
			@include breakpoint(large) {
				position: absolute;
				display: block;
				top: 40%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: transparent;
				.Legend {
					p {
						display: block;
						font-size: 0.875rem;
					}
					h3 {
						font-size: 2.7rem;
					}
				}
			}
		}
		a {
			position: absolute;
			display: none;
			background-color: transparent;
			border: 1px solid white;
			padding: 0.4rem 1.2rem;
			top: 74%;
			font-size: 0.625rem;
			color: white;
			left: 50%;
			transform: translatex(-50%);
			transition: ease-in-out 0.5s;
			z-index: 1001;
			&:hover {
				background-color: white;
				color: $pink;
			}
			@include breakpoint(medium) {
				display: block;
				padding: 0.5rem 1.6rem;
				top: 55%;
				font-size: 0.875rem;
			}
			@include breakpoint (large) {
				display: block;
				padding: 0.875rem 2rem;
				font-size: 1.2rem;
				top: 65%;
			}
		}
	}
	.slick-dots {
		bottom: 0;
		margin: 0 0 0 0;
		display: none !important;
		li {
			button {
				background: $gray;
				border-radius: 50%;
				height: 10px;
				width: 10px;
			}
			&.slick-active button {
				background-color: $pink;
			}
		}
		@include breakpoint(medium large) {
			display: block !important;
			background-color: #ACACAC;
			li {
				button {
					height: 16px;
					width: 16px;
				}
			}
		}
	}
	.slick-dots li button:before {
		display: none;
	}
}

.text-bottom-slide {
	position: relative;
	background-color: $pink;
	display: block;
	width: 100%;
	height: auto;
	padding: 1rem;
	p {
		font-size: 1rem;
		color: white;
		text-align: center;
		margin: 0 0 0.2rem 0;
	}
	h3 {
		font-size: 2rem;
		color: white;
		text-align: center;
		margin: 0 0 0.2rem 0;
		line-height: 0.8;
	}
	@include breakpoint(medium large) {
		display: none;
	}
}

#text-slide {
	position: relative;
	display: none;
	img {
		position: absolute;
		max-width: 20%;
		bottom: 30%;
		left: 50%;
		transform: translate(-50%, -30%);
	}
	@include breakpoint(medium large) {
		display: block;
	}
}
