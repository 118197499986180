@import url(https://fonts.googleapis.com/css?family=Halant:400,500,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700);

@font-face {
	font-family: 'Nocevento Sans'; font-style: bold; font-weight: 400;
	src: url('/fonts/NovecentoSansWideNormal.otf');
}

$font-novecento: 'Novecento Sans', sans-serif;
$font-halant: 'Halant', serif;
$font-open: 'Open Sans', sans-serif;
