div.Home {
	section#services {
		.border-double {
			border-color: $pink;
			border-style: solid;
			position: relative;
			&>div {
				text-align: center;
				margin: 0;
				border-color: $pink;
				border-style: solid;
				padding: 1.2rem 1rem;
				h3 {
					font-size: rem;
					text-transform: uppercase;
					margin: 0;
					@include breakpoint(medium large) {
						font-size: 2.375rem;
					}
				}
				p {
					font-size: 0.875rem;
					line-height: 1.4;
				}
			}
			&.top {
				padding: 10px 10px 0 10px;
				border-width: 2px 2px 0 2px;
				&>div {
					border-width: 2px 2px 0 2px;
				}
			}
			&.bottom {
				padding: 0 10px 10px 10px;
				border-width: 0 2px 2px 2px;
				&>div {
					border-width: 0 2px 2px 2px;
				}
			}
		}
		.service-box {
			position: relative;
			top: 0;
			width: 100%;
			height: 246px;
			transition: all 0.5s;
			outline: none;
			padding: 1rem;
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
			overflow: hidden;
			.Text-Title {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.5s;
				border: 1px solid #ffffff;
				padding: 1rem;
				p {
					font-family: $font-halant;
					color: white;
					font-size: 1.5rem;
					margin-bottom: 0;
					line-height: 1;
					text-transform: uppercase;
					text-align: center;
					@include breakpoint (medium large) {
						font-size: 2rem;
					}
				}
			}
			&.planning {
				height: 492px;
			}
			&:hover {
				background-size: 110%;
			}
			@media (max-width: 768px) {
				background-size: cover;
				height: 150px;
				&.planning {
					height: 300px;
				}
			}
			@media (max-width: 1200px) {
				height: 180px;
				&.planning {
					height: 360px;
				}
			}
			@media (min-width: 1200px) {
				height: 246px;
				&.planning {
					height: 492px;
				}
			}
		}
	}
	section.Gallery {
		line-height: 0;
		a {
			position: relative;
			max-width: 100%;
			width: 100%;
			height: auto;
			line-height: 0;
			text-align: center;
			overflow: hidden;
			.text {
				position: absolute;
				transform: translate3d(0, 0, -4px) scale(5);
				width: 100%;
				z-index: 1000;
				top: 0;
				left: 0;
				h4.gallery-name {
					font-size: 2.5rem;
					color: white;
					font-family: $font-halant;
					text-transform: uppercase;
					@include breakpoint(medium large) {
						font-size: 4.5rem;
					}
				}
				span.view-gallery {
					color: white;
					border: 1px solid white;
					padding: 0.5rem;
					font-family: $font-open;
					font-size: 1rem;
					text-transform: uppercase;
				}
			}
		}
	}
	section#testimonials {
		background-image: url(/images/bg/backgroundTestimonials.jpg);
		@extend .section-shadow;
		.Info-Test {
			position: relative;
			padding-top: 2rem;
			text-align: center;
			hgroup {
				text-align: center;
				margin-bottom: 2rem;
				h2 {
					font-family: $font-halant;
					font-size: 1.875rem;
					color: $gray-medium;
					text-transform: uppercase;
					margin-bottom: 0;
				}
				h3 {
					font-family: $font-open;
					font-size: 1rem;
					color: $pink;
					text-transform: none;
					margin-bottom: 0;
				}
			}
			p {
				font-family: $font-open;
				font-size: 0.875rem;
				text-align: justify;
			}
			img.Symbol-Test {
				display: inline-block;
			}
		}
		img.Testimonial--Image {
			margin: 0 auto;
		}
	}
	section#about {
		img.about-image {
			border: 1px solid $pink;
			padding: 0.8rem;
			margin: 0 auto 2rem auto;
			@include breakpoint (medium large) {
				margin: 0;
			}
		}
		h2 {
			text-align: center;
			margin-bottom: 0;
		}
		p {
			font-family: $font-open;
			font-size: 0.8125rem;
		}
	}
	section#team {
		background-image: url(/images/bg/backgroundTeam.jpg);
		h2.Header {
			color: white;
		}
		.Person-Box {
			position: relative;
			height: 100%;
			width: 100%;
			overflow: hidden;
			display: block;
			transition: all 0.7s;
			margin-bottom: 0 0 1rem 0;
			border: 10px solid #ffffff;
			img.Person {
				width: 100%;
				height: 100%;
				transition: all 0.7s;
			}
			.Tpt-Border {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				padding: 0.875rem;
				background-color: transparent;
				transition: all 0.7s;
				@include breakpoint(medium large) {
					padding: 1.25rem;
				}
				.Info-Team {
					display: none;
					position: relative;
					height: 20%;
					top: 80%;
					width: 100%;
					padding: 0.5rem;
					overflow: hidden;
					background-color: rgba(255, 255, 255, 0.8);
					transition: all 0.7s;
					@include breakpoint(medium large) {
						padding: 2rem;
					}
					span.Cws {
						margin: 0;
						font-size: 0.8125rem;
						line-height: 0.2;
						hr.pink-line {
							margin: 10px 0;
						}
						small {
							font-family: $font-open;
							font-size: 0.5rem;
							text-transform: lowercase;
						}
						@include breakpoint(medium large) {
							margin-bottom: 1.8rem;
							font-size: 1.875rem;
							small {
								font-family: $font-open;
								font-size: 1rem;
								text-transform: uppercase;
							}
						}
					}
					p {
						font-family: $font-open;
						font-size: 0.75rem;
						@include breakpoint(medium large) {
							font-size: 0.75rem;
						}
					}
					@include breakpoint(large) {
						display: block;
					}
				}
				.Info-Team-Responsive {
					position: relative;
					width: 100%;
					height: 100%;
					a.btn-Responsive {
						position: absolute;
						bottom: 0;
						display: block;
						width: 100%;
						font-family: $font-halant;
						font-size: 1.3rem;
						color: $gray-medium;
						text-transform: uppercase;
						text-align: center;
						padding: 1.5rem 0.8rem 0.8rem 0.8rem;
						background-color: rgba(255, 255, 255, 0.8);
						line-height: 0.7;
						outline: none;
						span {
							font-family: $font-open;
							font-size: 0.9rem;
							text-transform: capitalize;
							color: $pink;
						}
					}
					@include breakpoint(large) {
						display: none;
						a.btn-Responsive {
							display: none;
						}
					}
				}
				a.Contact-Team {
					position: absolute;
					padding: 1rem;
					left: 35%;
					transform: translatex(-50%);
					color: $gray-medium;
					background-color: transparent;
					border-right: 1px solid $pink;
					font-size: 1.1725rem;
					transition: all 0.5s;
					&:hover {
						border-right: 1px solid $gray-medium;
						color: $pink;
					}
				}
				ul.Social-Header {
					position: relative;
					margin-left: 50%;
					transform: translateX(-50%);
					li {
						a {
							color: $pink;
							font-size: 0.8125rem;
							@include breakpoint (medium large) {
								font-size: 1.5rem;
							}
						}
					}
				}
			}
			&:hover {
				img.Person {
					filter: blur(5px);
				}
				.Tpt-Border {
					.Info-Team {
						height: 100%;
						top: 0;
						span.Cws {}
					}
				}
			}
		}
	}
	section#blog {
		.article-container {
			.img-link {
				img {
					width: 100%;
				}
			}
			a.post-name {
				h6 {
					font-size: 1.4rem;
					color: $gray-medium;
					text-align: left;
					margin: 1rem 0 0 0;
					line-height: 1;
				}
			}
			hr.title-break {
				display: block;
				width: 100%;
				background-color: $pink;
				height: 1px;
				margin: 1rem 0;
			}
			p.post-detail {
				font-size: 0.875rem;
			}
			a.continue {
				color: $pink;
				border: none;
				background: transparent;
				text-align: center;
				width: 100%;
				line-height: 0.7;
				&:after {
					content: '-';
					display: block;
					font-size: 2rem;
					color: $pink;
				}
			}
			.Send {
				&:hover {
					color: $gray;
				}
			}
		}
		div.lines-button {
			background-color: transparent;
			border-color: $pink;
			border-width: 2px;
			border-left: 0;
			border-right: 0;
			border-style: solid;
			padding: 5px;
			width: 100%;
			position: inherit;
			top: 40%;
		}
	}
	section#instagram {
		@extend .section-shadow;
		.Instagram-Container {
			margin: 0;
			max-width: 100%;
			width: 100%;
			padding: 5px 0;
			box-shadow: 4px 0 4px 0px $gray-medium;
		}
	}
	section#contact {
		h5 {
			font-family: $font-halant;
			font-size: 1.25rem;
			color: $gray;
			margin-bottom: 0;
		}
		span {
			width: 100%;
			background-color: $pink;
			display: block;
			height: 1px;
			margin-bottom: 1rem;
		}
		.Info-Contact {
			padding-left: 0;
			p {
				font-family: $font-open;
				font-size: 0.9375rem;
				text-align: left;
				line-height: 1.5;
			}
			p.Place {
				font-size: 0.8125rem;
			}
			a.Phone,
			a.Email {
				color: $gray-medium;
				line-height: 1.5;
				&:hover {
					color: $pink;
				}
			}
			ul.Social-Header {
				li {
					float: left;
					a {
						margin-left: 0;
						margin-right: 1.3rem;
					}
				}
			}
			@include breakpoint(medium large) {
				padding-left: 2rem;
			}
		}
		#MainMap {
			height: 450px;
			width: 100%;
		}
	}
}

hr.separator {
	border: none;
	margin: 1rem;
	box-shadow: none;
	width: 100%;
	display: inline-block;
	clear: both;
}

section.Contact-thankyou {
	h1 {
		font-family: $font-halant;
		font-size: 2.5rem;
		color: $gray;
		margin: 1rem auto;
	}
	h5 {
		font-family: $font-halant;
		font-size: 1.25rem;
		color: $gray;
		margin-bottom: 6px;
	}
	.Info-Contact {
		padding-left: 0;
		span {
			width: 100%;
			background-color: $pink;
			display: block;
			height: 1px;
			margin-bottom: 1rem;
		}
		p {
			font-family: $font-open;
			font-size: 0.9375rem;
			text-align: left;
			line-height: 1.5;
		}
		p.Place {
			font-size: 0.8125rem;
		}
		a.Phone,
		a.Email {
			color: $gray-medium;
			line-height: 1.5;
			&:hover {
				color: $pink;
			}
		}
		ul.Social-Header {
			li {
				float: left;
				a {
					margin-left: 0;
					margin-right: 1.3rem;
				}
			}
		}
	}
}
