a.Phone,
a.Email {
	color: white;
	&:hover {
		color: $pink;
	}
}

a.Send {
	font-family: $font-halant;
	font-size: 1.15rem;
	background-color: $pink;
	border: 1px solid transparent;
	color: white;
	padding: 0.875rem 1rem;
	transition: all 0.5s;
	margin: 1rem;
	&:hover {
		background-color: transparent;
		border: 1px solid $pink;
		color: $pink;
	}
}

a.Btn-White {
	position: relative;
	left: 50%;
	transform: translatex(-50%);
	background-color: white;
	border: 1px solid $pink-light;
	color: $gray;
	padding: 0.875rem 1.2rem;
	transition: all 0.5s;
	&:hover {
		background-color: $pink-light;
		color: white;
	}
}

a.Logo {
	position: relative;
	left: 50%;
	transform: translatex(-50%);
}

button.inquire {
	background-color: $pink;
	transition: all 0.7s;
	margin-bottom: 0;
	margin-top: 1rem;
	font-weight: bold;
	font-size: 1.4rem;
	padding: 11px 6px 7px 6px;
	@include breakpoint(medium large) {
		margin-top: 0;
	}
	&:hover,
	&:focus {
		background-color: $gray;
	}
}
