@charset "UTF-8";
/*
|--------------------------------------------------------------------------
|	Vendor Imports
|--------------------------------------------------------------------------
*/
@import url(https://fonts.googleapis.com/css?family=Halant:400,500,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700);
[v-cloak] {
  display: none;
}

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
}

.row::before, .row::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.row::after {
  clear: both;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.gutter-small > .column, .row.gutter-small > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.row.gutter-medium > .column, .row.gutter-medium > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right;
}

.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.columns {
  float: none;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  width: 8.3333333333%;
}

.small-push-1 {
  position: relative;
  left: 8.3333333333%;
}

.small-pull-1 {
  position: relative;
  left: -8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  width: 16.6666666667%;
}

.small-push-2 {
  position: relative;
  left: 16.6666666667%;
}

.small-pull-2 {
  position: relative;
  left: -16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  width: 25%;
}

.small-push-3 {
  position: relative;
  left: 25%;
}

.small-pull-3 {
  position: relative;
  left: -25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  width: 33.3333333333%;
}

.small-push-4 {
  position: relative;
  left: 33.3333333333%;
}

.small-pull-4 {
  position: relative;
  left: -33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  width: 41.6666666667%;
}

.small-push-5 {
  position: relative;
  left: 41.6666666667%;
}

.small-pull-5 {
  position: relative;
  left: -41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  width: 50%;
}

.small-push-6 {
  position: relative;
  left: 50%;
}

.small-pull-6 {
  position: relative;
  left: -50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  width: 58.3333333333%;
}

.small-push-7 {
  position: relative;
  left: 58.3333333333%;
}

.small-pull-7 {
  position: relative;
  left: -58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  width: 66.6666666667%;
}

.small-push-8 {
  position: relative;
  left: 66.6666666667%;
}

.small-pull-8 {
  position: relative;
  left: -66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  width: 75%;
}

.small-push-9 {
  position: relative;
  left: 75%;
}

.small-pull-9 {
  position: relative;
  left: -75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  width: 83.3333333333%;
}

.small-push-10 {
  position: relative;
  left: 83.3333333333%;
}

.small-pull-10 {
  position: relative;
  left: -83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  width: 91.6666666667%;
}

.small-push-11 {
  position: relative;
  left: 91.6666666667%;
}

.small-pull-11 {
  position: relative;
  left: -91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%;
}

.small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
  clear: both;
}

.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%;
}

.small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
  clear: both;
}

.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.3333333333%;
}

.small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}

.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%;
}

.small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}

.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%;
}

.small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}

.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.6666666667%;
}

.small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}

.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.2857142857%;
}

.small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}

.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%;
}

.small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}

.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-collapse .row {
  margin-right: 0;
  margin-left: 0;
}

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.small-centered {
  margin-right: auto;
  margin-left: auto;
}

.small-centered, .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0;
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.3333333333%;
  }
  .medium-push-1 {
    position: relative;
    left: 8.3333333333%;
  }
  .medium-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    width: 16.6666666667%;
  }
  .medium-push-2 {
    position: relative;
    left: 16.6666666667%;
  }
  .medium-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    width: 25%;
  }
  .medium-push-3 {
    position: relative;
    left: 25%;
  }
  .medium-pull-3 {
    position: relative;
    left: -25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    width: 33.3333333333%;
  }
  .medium-push-4 {
    position: relative;
    left: 33.3333333333%;
  }
  .medium-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    width: 41.6666666667%;
  }
  .medium-push-5 {
    position: relative;
    left: 41.6666666667%;
  }
  .medium-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    width: 50%;
  }
  .medium-push-6 {
    position: relative;
    left: 50%;
  }
  .medium-pull-6 {
    position: relative;
    left: -50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    width: 58.3333333333%;
  }
  .medium-push-7 {
    position: relative;
    left: 58.3333333333%;
  }
  .medium-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    width: 66.6666666667%;
  }
  .medium-push-8 {
    position: relative;
    left: 66.6666666667%;
  }
  .medium-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    width: 75%;
  }
  .medium-push-9 {
    position: relative;
    left: 75%;
  }
  .medium-pull-9 {
    position: relative;
    left: -75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    width: 83.3333333333%;
  }
  .medium-push-10 {
    position: relative;
    left: 83.3333333333%;
  }
  .medium-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    width: 91.6666666667%;
  }
  .medium-push-11 {
    position: relative;
    left: 91.6666666667%;
  }
  .medium-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .medium-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.3333333333%;
  }
  .large-push-1 {
    position: relative;
    left: 8.3333333333%;
  }
  .large-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    width: 16.6666666667%;
  }
  .large-push-2 {
    position: relative;
    left: 16.6666666667%;
  }
  .large-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    width: 25%;
  }
  .large-push-3 {
    position: relative;
    left: 25%;
  }
  .large-pull-3 {
    position: relative;
    left: -25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    width: 33.3333333333%;
  }
  .large-push-4 {
    position: relative;
    left: 33.3333333333%;
  }
  .large-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    width: 41.6666666667%;
  }
  .large-push-5 {
    position: relative;
    left: 41.6666666667%;
  }
  .large-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    width: 50%;
  }
  .large-push-6 {
    position: relative;
    left: 50%;
  }
  .large-pull-6 {
    position: relative;
    left: -50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    width: 58.3333333333%;
  }
  .large-push-7 {
    position: relative;
    left: 58.3333333333%;
  }
  .large-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    width: 66.6666666667%;
  }
  .large-push-8 {
    position: relative;
    left: 66.6666666667%;
  }
  .large-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    width: 75%;
  }
  .large-push-9 {
    position: relative;
    left: 75%;
  }
  .large-pull-9 {
    position: relative;
    left: -75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    width: 83.3333333333%;
  }
  .large-push-10 {
    position: relative;
    left: 83.3333333333%;
  }
  .large-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    width: 91.6666666667%;
  }
  .large-push-11 {
    position: relative;
    left: 91.6666666667%;
  }
  .large-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .large-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}

.column-block {
  margin-bottom: 1.25rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row.is-collapse-child,
.row.collapse > .column > .row,
.row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}

@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}

.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}

.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}

.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}

.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}

.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}

.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}

.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}

.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 64em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #cacaca;
}

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem;
  }
  h2, .h2 {
    font-size: 2.5rem;
  }
  h3, .h3 {
    font-size: 1.9375rem;
  }
  h4, .h4 {
    font-size: 1.5625rem;
  }
  h5, .h5 {
    font-size: 1.25rem;
  }
  h6, .h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:focus {
  color: #1468a0;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}

blockquote, blockquote p {
  line-height: 1.6;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a;
}

cite:before {
  content: "— ";
}

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}

[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0;
}

input[type='search'] {
  box-sizing: border-box;
}

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem;
}

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

[type='checkbox'] + label[for],
[type='radio'] + label[for] {
  cursor: pointer;
}

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem;
}

[type='file'] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a;
}

label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch;
}

.input-group > :first-child {
  border-radius: 0 0 0 0;
}

.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: auto;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca;
}

.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

@media screen and (min-width: 0\0) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}

select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea;
}

.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}

.is-invalid-label {
  color: #cc4b37;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37;
}

.form-error.is-visible {
  display: block;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe;
}

[data-whatinput='mouse'] .button {
  outline: 0;
}

.button:hover, .button:focus {
  background-color: #14679e;
  color: #fefefe;
}

.button.tiny {
  font-size: 0.6rem;
}

.button.small {
  font-size: 0.75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button.primary {
  background-color: #1779ba;
  color: #fefefe;
}

.button.primary:hover, .button.primary:focus {
  background-color: #126195;
  color: #fefefe;
}

.button.secondary {
  background-color: #767676;
  color: #fefefe;
}

.button.secondary:hover, .button.secondary:focus {
  background-color: #5e5e5e;
  color: #fefefe;
}

.button.success {
  background-color: #3adb76;
  color: #0a0a0a;
}

.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}

.button.warning {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}

.button.alert {
  background-color: #cc4b37;
  color: #fefefe;
}

.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #fefefe;
}

.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #1779ba;
  color: #fefefe;
}

.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #1779ba;
  color: #fefefe;
}

.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #767676;
  color: #fefefe;
}

.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #0a0a0a;
}

.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #fefefe;
}

.button.hollow {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}

.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent;
}

.button.hollow:hover, .button.hollow:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}

.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.hollow.primary {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}

.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.hollow.secondary {
  border: 1px solid #767676;
  color: #767676;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}

.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676;
}

.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.clear, .button.clear:hover, .button.clear:focus {
  background-color: transparent;
}

.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: transparent;
}

.button.clear:hover, .button.clear:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}

.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: transparent;
}

.button.clear.primary {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.clear.primary:hover, .button.clear.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}

.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: transparent;
}

.button.clear.secondary {
  border: 1px solid #767676;
  color: #767676;
}

.button.clear.secondary:hover, .button.clear.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}

.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676;
}

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: transparent;
}

.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success:hover, .button.clear.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: transparent;
}

.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning:hover, .button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: transparent;
}

.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert:hover, .button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: transparent;
}

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}

.button.dropdown.hollow::after {
  border-top-color: #1779ba;
}

.button.dropdown.hollow.primary::after {
  border-top-color: #1779ba;
}

.button.dropdown.hollow.secondary::after {
  border-top-color: #767676;
}

.button.dropdown.hollow.success::after {
  border-top-color: #3adb76;
}

.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}

.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37;
}

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: '+';
}

.is-active > .accordion-title::before {
  content: '\2013';
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a;
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.accordion-menu li {
  width: 100%;
}

.accordion-menu a {
  padding: 0.7rem 1rem;
}

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem;
}

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem;
}

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem;
}

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem;
}

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem;
}

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.is-accordion-submenu-parent {
  position: relative;
}

.has-submenu-toggle > a {
  margin-right: 40px;
}

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.submenu-toggle::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  top: 0;
  bottom: 0;
  margin: auto;
}

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%;
}

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

.button-group::before, .button-group::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.button-group::after {
  clear: both;
}

.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem;
  flex: 0 0 auto;
}

.button-group .button:last-child {
  margin-right: 0;
}

.button-group.tiny .button {
  font-size: 0.6rem;
}

.button-group.small .button {
  font-size: 0.75rem;
}

.button-group.large .button {
  font-size: 1.25rem;
}

.button-group.expanded .button {
  flex: 1 1 0px;
}

.button-group.primary .button {
  background-color: #1779ba;
  color: #fefefe;
}

.button-group.primary .button:hover, .button-group.primary .button:focus {
  background-color: #126195;
  color: #fefefe;
}

.button-group.secondary .button {
  background-color: #767676;
  color: #fefefe;
}

.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  background-color: #5e5e5e;
  color: #fefefe;
}

.button-group.success .button {
  background-color: #3adb76;
  color: #0a0a0a;
}

.button-group.success .button:hover, .button-group.success .button:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}

.button-group.warning .button {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button-group.warning .button:hover, .button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}

.button-group.alert .button {
  background-color: #cc4b37;
  color: #fefefe;
}

.button-group.alert .button:hover, .button-group.alert .button:focus {
  background-color: #a53b2a;
  color: #fefefe;
}

.button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
  flex-wrap: wrap;
}

.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  flex: 0 0 100%;
}

.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .button-group.stacked-for-small .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 39.9375em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a;
}

.callout > :first-child {
  margin-top: 0;
}

.callout > :last-child {
  margin-bottom: 0;
}

.callout.primary {
  background-color: #d7ecfa;
  color: #0a0a0a;
}

.callout.secondary {
  background-color: #eaeaea;
  color: #0a0a0a;
}

.callout.success {
  background-color: #e1faea;
  color: #0a0a0a;
}

.callout.warning {
  background-color: #fff3d9;
  color: #0a0a0a;
}

.callout.alert {
  background-color: #f7e4e1;
  color: #0a0a0a;
}

.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}

[data-whatinput='mouse'] .close-button {
  outline: 0;
}

.close-button:hover, .close-button:focus {
  color: #0a0a0a;
}

.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}

.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

[data-whatinput='mouse'] .menu li {
  outline: 0;
}

.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}

.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}

.menu input {
  display: inline-block;
}

.menu, .menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}

.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}

.menu.expanded li {
  flex: 1 1 0px;
}

.menu.simple {
  align-items: center;
}

.menu.simple li + li {
  margin-left: 1rem;
}

.menu.simple a {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.medium-expanded li {
    flex: 1 1 0px;
  }
  .menu.medium-simple li {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.large-expanded li {
    flex: 1 1 0px;
  }
  .menu.large-simple li {
    flex: 1 1 0px;
  }
}

.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}

.menu.icons a {
  display: flex;
}

.menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
  display: flex;
}

.menu.icon-left li a {
  flex-flow: row nowrap;
}

.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
}

.menu.icon-right li a {
  flex-flow: row nowrap;
}

.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
}

.menu.icon-top li a {
  flex-flow: column nowrap;
}

.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu.icon-bottom li a {
  flex-flow: column nowrap;
}

.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu .is-active > a {
  background: #1779ba;
  color: #fefefe;
}

.menu .active > a {
  background: #1779ba;
  color: #fefefe;
}

.menu.align-left {
  justify-content: flex-start;
}

.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}

.menu.align-right li .submenu li {
  justify-content: flex-start;
}

.menu.align-right.vertical li {
  display: block;
  text-align: right;
}

.menu.align-right.vertical li .submenu li {
  text-align: right;
}

.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}

.menu.align-center li {
  display: flex;
  justify-content: center;
}

.menu.align-center li .submenu li {
  justify-content: flex-start;
}

.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered > .menu {
  justify-content: center;
}

.menu-centered > .menu li {
  display: flex;
  justify-content: center;
}

.menu-centered > .menu li .submenu li {
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fefefe;
  box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
  content: '';
}

.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
  content: '';
}

.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem;
}

.dropdown-pane.is-opening {
  display: block;
}

.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px;
}

.dropdown.menu a {
  padding: 0.7rem 1rem;
}

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0;
}

.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba;
}

.no-js .dropdown.menu ul {
  display: none;
}

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0;
}

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.dropdown.menu.vertical > li > a::after {
  right: 14px;
}

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
}

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba;
}

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent;
  }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
  }
}

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent;
  }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
  }
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}

.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px;
}

.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe;
}

.dropdown .is-dropdown-submenu a {
  padding: 0.7rem 1rem;
}

.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba;
}

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}

.is-dropdown-submenu > li {
  width: 100%;
}

.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe;
}

.label.primary {
  background: #1779ba;
  color: #fefefe;
}

.label.secondary {
  background: #767676;
  color: #fefefe;
}

.label.success {
  background: #3adb76;
  color: #0a0a0a;
}

.label.warning {
  background: #ffae00;
  color: #0a0a0a;
}

.label.alert {
  background: #cc4b37;
  color: #fefefe;
}

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}

.media-object img {
  max-width: none;
}

@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }
}

.media-object-section {
  flex: 0 1 auto;
}

.media-object-section:first-child {
  padding-right: 1rem;
}

.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}

.media-object-section > :last-child {
  margin-bottom: 0;
}

.media-object-section.main-section {
  flex: 1 1 0px;
}

.pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}

.pagination::before, .pagination::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.pagination::after {
  clear: both;
}

.pagination li {
  margin-right: 0.0625rem;
  border-radius: 0;
  font-size: 0.875rem;
  display: none;
}

.pagination li:last-child, .pagination li:first-child {
  display: inline-block;
}

@media print, screen and (min-width: 40em) {
  .pagination li {
    display: inline-block;
  }
}

.pagination a,
.pagination button {
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
  color: #0a0a0a;
}

.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6;
}

.pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #1779ba;
  color: #fefefe;
  cursor: default;
}

.pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #cacaca;
  cursor: not-allowed;
}

.pagination .disabled:hover {
  background: transparent;
}

.pagination .ellipsis::after {
  padding: 0.1875rem 0.625rem;
  content: '\2026';
  color: #0a0a0a;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab';
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb';
}

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca;
}

.progress.primary .progress-meter {
  background-color: #1779ba;
}

.progress.secondary .progress-meter {
  background-color: #767676;
}

.progress.success .progress-meter {
  background-color: #3adb76;
}

.progress.warning .progress-meter {
  background-color: #ffae00;
}

.progress.alert .progress-meter {
  background-color: #cc4b37;
}

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba;
}

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap;
}

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out;
}

.slider-fill.is-dragging {
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation;
}

[data-whatinput='mouse'] .slider-handle {
  outline: 0;
}

.slider-handle:hover {
  background-color: #14679e;
}

.slider-handle.is-dragging {
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1);
}

.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%;
}

.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%);
}

.sticky-container {
  position: relative;
}

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0);
}

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%;
}

.sticky.is-stuck.is-at-top {
  top: 0;
}

.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto;
}

.sticky.is-anchored.is-at-bottom {
  bottom: 0;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

[data-whatinput='mouse'] .reveal {
  outline: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column, .reveal .columns {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 75rem;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 75rem;
  }
}

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

@media screen and (max-width: 39.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}

input + .switch-paddle {
  margin: 0;
}

.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #fefefe;
  transition: all 0.25s ease-out;
  content: '';
}

input:checked ~ .switch-paddle {
  background: #1779ba;
}

input:checked ~ .switch-paddle::after {
  left: 2.25rem;
}

[data-whatinput='mouse'] input:focus ~ .switch-paddle {
  outline: 0;
}

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}

input:checked + label > .switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}

input:checked + label > .switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}

.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.625rem;
}

.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.switch.tiny input:checked ~ .switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}

.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 0.75rem;
}

.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}

.switch.small input:checked ~ .switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}

.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1rem;
}

.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem;
}

.switch.large input:checked ~ .switch-paddle::after {
  left: 2.75rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}

table thead,
table tbody,
table tfoot {
  border: 1px solid #f1f1f1;
  background-color: #fefefe;
}

table caption {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
}

table thead {
  background: #f8f8f8;
  color: #0a0a0a;
}

table tfoot {
  background: #f1f1f1;
  color: #0a0a0a;
}

table thead tr,
table tfoot tr {
  background: transparent;
}

table thead th,
table thead td,
table tfoot th,
table tfoot td {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
  text-align: left;
}

table tbody th,
table tbody td {
  padding: 0.5rem 0.625rem 0.625rem;
}

table tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f1f1f1;
}

table.unstriped tbody {
  background-color: #fefefe;
}

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f1f1f1;
  background-color: #fefefe;
}

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #f3f3f3;
}

table.hover tfoot tr:hover {
  background-color: #ececec;
}

table.hover tbody tr:hover {
  background-color: #f9f9f9;
}

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec;
}

.table-scroll {
  overflow-x: auto;
}

.table-scroll table {
  width: auto;
}

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none;
}

.tabs::before, .tabs::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}

.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #1779ba;
}

.tabs.primary > li > a {
  color: #fefefe;
}

.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #1673b1;
}

.tabs-title {
  float: left;
}

.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba;
}

.tabs-title > a:hover {
  background: #fefefe;
  color: #1468a0;
}

.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #e6e6e6;
  color: #1779ba;
}

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}

.tabs-panel.is-active {
  display: block;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0;
}

a.thumbnail {
  transition: box-shadow 200ms ease-out;
}

a.thumbnail:hover, a.thumbnail:focus {
  box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5);
}

a.thumbnail image {
  box-shadow: none;
}

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe;
}

.tooltip::before {
  position: absolute;
}

.tooltip.bottom::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0a0a0a;
  bottom: 100%;
}

.tooltip.bottom.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent;
  top: 100%;
  bottom: auto;
}

.tooltip.top.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0a0a0a;
  left: 100%;
}

.tooltip.left.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0a0a0a transparent transparent;
  right: 100%;
  left: auto;
}

.tooltip.right.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.align-top::before {
  bottom: auto;
  top: 10%;
}

.tooltip.align-bottom::before {
  bottom: 10%;
  top: auto;
}

.tooltip.align-left::before {
  left: 10%;
  right: auto;
}

.tooltip.align-right::before {
  left: auto;
  right: 10%;
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
}

.top-bar,
.top-bar ul {
  background-color: #e6e6e6;
}

.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}

.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}

.top-bar input.button {
  width: auto;
}

.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
    margin-right: auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clearfix::after {
  clear: both;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/*!
	Modaal - accessible modals - v0.3.1
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden;
}

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out;
}

.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}

.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}

.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}

.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}

.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}

.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}

.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px;
}

.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}

.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}

.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #b93d0c;
}

.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}

.modaal-close:before {
  transform: rotate(-45deg);
}

.modaal-close:after {
  transform: rotate(45deg);
}

.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content-container {
  padding: 30px;
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}

.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  transition: background 0.2s ease-in-out;
}

.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}

.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}

.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards;
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px;
}

.modaal-image .modaal-container {
  width: auto;
  max-width: 100%;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}

.modaal-gallery-item img {
  display: block;
}

.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}

.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}

.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}

.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  transition: background 0.2s ease-in-out;
}

.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}

.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next {
  left: 100%;
  margin-left: 40px;
}

.modaal-gallery-prev {
  right: 100%;
  margin-right: 40px;
}

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}

.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}

.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%;
}

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
  }
}

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}

@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none;
  }
}

.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}

/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
@font-face {
  font-family: 'Nocevento Sans';
  font-style: bold;
  font-weight: 400;
  src: url("/fonts/NovecentoSansWideNormal.otf");
}

/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/
[v-cloak] {
  display: none;
}

html {
  font-size: 16px;
}

body {
  position: relative;
  font-family: "Halant", serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  height: 100%;
}

a {
  display: inline-block;
  color: white;
  font-weight: 400;
}

a:focus, a:hover {
  color: #eabfd3;
}

p {
  text-align: justify;
  color: #808080;
  line-height: 1.2;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Halant", serif;
  font-size: 2.2rem;
  color: #A6A8AB;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}

h1.Header,
h2.Header {
  margin-top: 2rem;
}

span.Cws, span.Cws-Footer {
  font-family: "Halant", serif;
  font-size: 2rem;
  color: #A6A8AB;
  text-transform: uppercase;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
  line-height: 1.5rem;
}

span.Cws small, span.Cws-Footer small {
  font-family: "Novecento Sans", sans-serif;
  font-size: 0.7rem;
  color: #eabfd3;
  display: block;
  line-height: 1;
}

@media screen and (min-width: 40em) {
  span.Cws, span.Cws-Footer {
    margin: 0;
    font-size: 2.7rem;
    line-height: 1.2rem;
  }
}

span.Cws-Footer {
  font-size: 2.3rem;
  color: white;
}

span.Cws-Footer small {
  font-size: 0.6rem;
  color: white;
}

hr.BigSeparator, hr.Invisible, hr.Invisible-Small, hr.Invisible-ExSmall {
  border: none;
  margin: 3.125rem;
  box-shadow: none;
  width: 100%;
  display: inline-block;
  clear: both;
}

hr.Invisible {
  background-color: transparent;
}

hr.Invisible-Small {
  background-color: transparent;
  margin: 1rem;
}

hr.Invisible-ExSmall {
  background-color: transparent;
  margin: 1rem;
}

ul.sitemap {
  list-style: none;
}

ul.sitemap li a {
  color: #808080;
}

img.Head {
  position: relative;
  left: 50%;
  transform: translatex(-50%);
  margin-bottom: 2.3rem;
  width: 79px;
  height: 6px;
}

.Bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.np {
  padding: 0;
}

.np img {
  width: 100%;
}

.relative {
  position: relative;
}

.modaal-container {
  background: none;
}

.modaal-content-container {
  padding: 0 10px;
  margin-left: 0;
  transform: translateX(0);
}

@media screen and (min-width: 40em) {
  .modaal-content-container {
    padding: 0;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.modaal-close {
  background-color: #eabfd3 !important;
}

.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: none;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #eabfd3;
}

@media print, screen and (min-width: 64em) {
  .modaal-close {
    background-color: transparent !important;
  }
}

@media screen and (min-width: 40em) {
  .Size-Icons {
    max-width: 50%;
    max-height: 50%;
  }
}

.Flex {
  display: block;
}

@media screen and (min-width: 40em) {
  .Flex {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

.padding-header {
  padding-top: 1rem;
  padding-bottom: 0;
}

@media screen and (min-width: 40em) {
  .padding-header {
    padding-top: 1.8rem;
    padding-bottom: 0.875rem;
  }
}

.align-captacha {
  padding-left: 0.9375rem;
  margin-top: 1rem;
}

a.Phone,
a.Email {
  color: white;
}

a.Phone:hover,
a.Email:hover {
  color: #eabfd3;
}

a.Send {
  font-family: "Halant", serif;
  font-size: 1.15rem;
  background-color: #eabfd3;
  border: 1px solid transparent;
  color: white;
  padding: 0.875rem 1rem;
  transition: all 0.5s;
  margin: 1rem;
}

a.Send:hover {
  background-color: transparent;
  border: 1px solid #eabfd3;
  color: #eabfd3;
}

a.Btn-White {
  position: relative;
  left: 50%;
  transform: translatex(-50%);
  background-color: white;
  border: 1px solid #F5DFEA;
  color: #C5C5C5;
  padding: 0.875rem 1.2rem;
  transition: all 0.5s;
}

a.Btn-White:hover {
  background-color: #F5DFEA;
  color: white;
}

a.Logo {
  position: relative;
  left: 50%;
  transform: translatex(-50%);
}

button.inquire {
  background-color: #eabfd3;
  transition: all 0.7s;
  margin-bottom: 0;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 11px 6px 7px 6px;
}

@media screen and (min-width: 40em) {
  button.inquire {
    margin-top: 0;
  }
}

button.inquire:hover, button.inquire:focus {
  background-color: #C5C5C5;
}

ul.Social-Header {
  margin: 0;
  padding: 1rem 0 0 0;
  text-align: center;
}

ul.Social-Header li {
  display: inline-block;
}

ul.Social-Header li a {
  color: #C5C5C5;
  margin-left: 0.5rem;
  font-size: 2rem;
  opacity: 1;
}

ul.Social-Header li a:hover {
  color: #eabfd3;
}

@media screen and (min-width: 40em) {
  ul.Social-Header {
    margin: 0;
    padding-top: 0;
    text-align: right;
  }
  ul.Social-Header li a {
    margin-left: 2rem;
    font-size: 1.2rem;
  }
}

header .Top-Header {
  padding: 1rem 0;
  background-color: white;
  color: #808080;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
}

header .Top-Header p {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 0;
  line-height: 1.1rem;
  color: #808080;
}

header .Top-Header p small {
  font-size: 1.125rem;
}

header .Top-Header p a {
  color: #808080;
}

header .Top-Header p.links-contact {
  text-align: center;
}

header .Top-Header p.links-contact span {
  font-size: 0.875rem;
}

@media screen and (min-width: 40em) {
  header .Top-Header p.links-contact {
    text-align: left;
  }
}

header .Top-Header img.Logo {
  max-height: 85px;
}

header nav {
  background-color: #eabfd3;
  color: white;
  margin: 0;
  position: relative;
  height: auto;
  overflow: hidden;
  width: 100%;
}

header nav ul#theMenu.menu {
  list-style: none;
  margin: 0;
  display: block;
}

header nav ul#theMenu.menu li {
  position: relative;
  padding: 18px 0 10px 0;
  display: block;
}

@media (min-width: 640px) {
  header nav ul#theMenu.menu li {
    display: inline-block;
  }
}

header nav ul#theMenu.menu li a {
  color: white;
  display: block;
  font-family: "Halant", serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: inherit;
  padding: 0 5px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.8s;
}

header nav ul#theMenu.menu li a:hover {
  color: #808080;
  text-shadow: none;
}

@media (min-width: 951px) {
  header nav ul#theMenu.menu li a {
    padding: 0 1.0625rem;
    font-size: 1.125rem;
  }
}

@media (min-width: 720px) and (max-width: 950px) {
  header nav ul#theMenu.menu li a {
    padding: 0 8px;
  }
}

header nav ul#theMenu.menu li:after {
  content: '';
  width: 4px;
  height: 4px;
  font-family: 'FontAwesome';
  position: absolute;
  color: white;
  top: 25px;
  left: -2px;
  font-size: 4px;
}

@media (min-width: 640px) {
  header nav ul#theMenu.menu li:after {
    content: '\f111';
  }
}

@media (min-width: 951px) {
  header nav ul#theMenu.menu li:after {
    top: 26px;
    width: 4px;
    height: 4px;
    font-size: 6px;
    left: -4px;
  }
}

header nav ul#theMenu.menu li:first-child:after {
  content: '';
}

header nav ul#theMenu.menu li.activa a {
  color: #808080;
  text-shadow: none;
}

header span.Menu-Bottom {
  display: none;
  background-color: #eabfd3;
  border-top: 1px solid #b8b8b8;
  height: 5px;
  margin: 0 auto;
  padding-bottom: 5px;
}

@media screen and (min-width: 40em) {
  header span.Menu-Bottom {
    display: block;
  }
}

div#DesktopMenu {
  display: none;
}

@media screen and (min-width: 40em) {
  div#DesktopMenu {
    display: block;
  }
}

#Hamburguer {
  cursor: pointer;
  margin: 1rem auto;
  text-align: center;
  color: #eabfd3;
}

@media screen and (min-width: 40em) {
  #Hamburguer {
    display: none;
  }
}

/*
|--------------------------------------------------------------------------
| Slider
|--------------------------------------------------------------------------
*/
#Slider-Home {
  margin-bottom: 0;
}

#Slider-Home div {
  position: relative;
  overflow: hidden;
}

#Slider-Home div img {
  max-width: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: auto;
}

#Slider-Home div .bg-shadowSlide {
  position: absolute;
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
}

#Slider-Home div .Slider-Text {
  position: relative;
  display: none;
  z-index: 1001;
  height: 100px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

#Slider-Home div .Slider-Text .Legend {
  border: 2px solid white;
  padding: 5px;
}

#Slider-Home div .Slider-Text .Legend p {
  color: white;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 0.5rem;
  margin-bottom: 0;
  position: relative;
  width: 100%;
  text-transform: uppercase;
}

#Slider-Home div .Slider-Text .Legend p:before, #Slider-Home div .Slider-Text .Legend p:after {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: center;
  width: 15%;
  margin-right: 10px;
  margin-left: 10px;
  border-top: 1px solid white;
}

#Slider-Home div .Slider-Text .Legend h3 {
  color: white;
  font-size: 0.625rem;
  margin-left: 1.875rem;
  margin-right: 1.875rem;
  margin-bottom: 0;
  line-height: 1;
}

#Slider-Home div .Slider-Text .Legend h3 span {
  font-size: 4rem;
  letter-spacing: 8px;
}

@media print, screen and (min-width: 40em) {
  #Slider-Home div .Slider-Text {
    position: absolute;
    display: block;
  }
  #Slider-Home div .Slider-Text .Legend p {
    font-size: 0.825rem;
  }
  #Slider-Home div .Slider-Text .Legend h3 {
    font-size: 1.3rem;
  }
}

@media print, screen and (min-width: 64em) {
  #Slider-Home div .Slider-Text {
    position: absolute;
    display: block;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
  }
  #Slider-Home div .Slider-Text .Legend p {
    display: block;
    font-size: 0.875rem;
  }
  #Slider-Home div .Slider-Text .Legend h3 {
    font-size: 2.7rem;
  }
}

#Slider-Home div a {
  position: absolute;
  display: none;
  background-color: transparent;
  border: 1px solid white;
  padding: 0.4rem 1.2rem;
  top: 74%;
  font-size: 0.625rem;
  color: white;
  left: 50%;
  transform: translatex(-50%);
  transition: ease-in-out 0.5s;
  z-index: 1001;
}

#Slider-Home div a:hover {
  background-color: white;
  color: #eabfd3;
}

@media print, screen and (min-width: 40em) {
  #Slider-Home div a {
    display: block;
    padding: 0.5rem 1.6rem;
    top: 55%;
    font-size: 0.875rem;
  }
}

@media print, screen and (min-width: 64em) {
  #Slider-Home div a {
    display: block;
    padding: 0.875rem 2rem;
    font-size: 1.2rem;
    top: 65%;
  }
}

#Slider-Home .slick-dots {
  bottom: 0;
  margin: 0 0 0 0;
  display: none !important;
}

#Slider-Home .slick-dots li button {
  background: #C5C5C5;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

#Slider-Home .slick-dots li.slick-active button {
  background-color: #eabfd3;
}

@media screen and (min-width: 40em) {
  #Slider-Home .slick-dots {
    display: block !important;
    background-color: #ACACAC;
  }
  #Slider-Home .slick-dots li button {
    height: 16px;
    width: 16px;
  }
}

#Slider-Home .slick-dots li button:before {
  display: none;
}

.text-bottom-slide {
  position: relative;
  background-color: #eabfd3;
  display: block;
  width: 100%;
  height: auto;
  padding: 1rem;
}

.text-bottom-slide p {
  font-size: 1rem;
  color: white;
  text-align: center;
  margin: 0 0 0.2rem 0;
}

.text-bottom-slide h3 {
  font-size: 2rem;
  color: white;
  text-align: center;
  margin: 0 0 0.2rem 0;
  line-height: 0.8;
}

@media screen and (min-width: 40em) {
  .text-bottom-slide {
    display: none;
  }
}

#text-slide {
  position: relative;
  display: none;
}

#text-slide img {
  position: absolute;
  max-width: 20%;
  bottom: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}

@media screen and (min-width: 40em) {
  #text-slide {
    display: block;
  }
}

/*
|--------------------------------------------------------------------------
| Gallery Modals Various content
|--------------------------------------------------------------------------
*/
.Main--Gallery {
  position: relative;
  width: 100%;
  overflow: visible;
}

.Main--Gallery .Gallery--Category .slick-slide img {
  max-width: 100%;
  margin-top: 50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
}

.Main--Gallery .newdirectional i {
  position: absolute;
  top: 20%;
  color: white;
  font-size: 5rem;
  font-weight: 300;
  cursor: pointer;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.Main--Gallery .newdirectional i.lefticon {
  left: -10%;
}

.Main--Gallery .newdirectional i.righticon {
  right: -10%;
}

.modaal-gallery-next {
  margin-left: 0;
  right: -40px;
}

@media screen and (min-width: 40em) {
  .modaal-gallery-next {
    display: block !important;
    right: auto;
  }
}

.modaal-gallery-prev {
  margin-right: 0;
  left: -40px;
}

@media screen and (min-width: 40em) {
  .modaal-gallery-prev {
    display: block !important;
    left: auto;
  }
}

.modaal-gallery-control {
  height: 100%;
  border-radius: 0;
  width: 40px;
  bottom: 0px;
}

.modaal-gallery-control:hover, .modaal-gallery-control:focus {
  background-color: transparent;
}

.modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after, .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after {
  background-color: #eabfd3;
}

.modaal-gallery-control:before, .modaal-gallery-control:after {
  top: 50%;
  background-color: white;
}

@media screen and (min-width: 40em) {
  .modaal-gallery-control {
    width: 80px;
    bottom: 20px;
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    left: 40px;
  }
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 60px;
  padding-right: 60px;
}

@media screen and (min-width: 40em) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.pleasewait {
  display: none;
  opacity: 0.95;
}

.pleasewait h2 {
  text-align: center;
  margin-top: 20%;
}

#pleasewait {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  padding-top: 20%;
}

#pleasewait p {
  color: #fefefe;
}

#pleasewait h2 {
  font-size: 3em;
  color: #fefefe;
}

.callout {
  background-color: #eabfd3;
  padding: 1rem 4rem;
}

.callout ul {
  font-size: 1.2rem;
  font-weight: 600;
}

.callout h4 {
  color: white;
  text-align: center;
}

.callout.alert ul li {
  color: #8C8E91;
}

h4.text-success {
  font-size: 2rem;
  padding: 1rem;
  margin: 1rem 0 0 0;
  color: green;
}

h4.text-danger {
  padding: 1rem;
  margin: 1rem 0 0 0;
  color: red;
  font-size: 2rem;
}

.relative {
  position: relative;
}

.np {
  padding: 0;
}

a#toTop {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: #eabfd3;
  color: white;
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 1rem;
  display: none;
  z-index: 3000;
  cursor: pointer;
}

#msgSubmit {
  color: green;
  text-align: center;
}

.u__white {
  color: white;
}

hr.pink-line {
  position: relative;
  display: inline-grid;
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background-color: #eabfd3;
  border: none;
  max-width: 100%;
}

.full {
  max-width: 100%;
}

.img-zoom {
  max-width: 100%;
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
}

.img-zoom:hover {
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 4.5s ease-out;
  -webkit-transition: all 4.5s ease-out;
}

.section-shadow, div.Home section#testimonials, div.Home section#instagram {
  -webkit-box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
  -moz-box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
  box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
}

.gallery-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
}

.parallax {
  height: 320px;
  background: #fefefe;
  position: relative;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  background-attachment: fixed;
  -webkit-perspective: 1px;
  perspective: 1px;
}

@media only screen and (max-device-width: 1024px) {
  .parallax {
    background-attachment: scroll;
  }
}

.flowers {
  background-image: url(/images/gallery/bg-flowers.jpg);
}

.cakes {
  background-image: url(/images/gallery/bg-cakes.jpg);
}

.music {
  background-image: url(/images/gallery/bg-music.jpg);
}

.venues {
  background-image: url(/images/gallery/main-venues.jpg);
}

.details {
  background-image: url(/images/gallery/main-details.jpg);
}

.couples {
  background-image: url(/images/gallery/bg-couples.jpg);
}

/*
|--------------------------------------------------------------------------
| Gallery Modals Various content
|--------------------------------------------------------------------------
*/
.Main--Gallery {
  position: relative;
  width: 100%;
  overflow: visible;
}

.Main--Gallery .Gallery--Category .slick-slide img {
  max-width: 100%;
  margin-top: 50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
}

.Main--Gallery .newdirectional i {
  position: absolute;
  top: 20%;
  color: white;
  font-size: 5rem;
  font-weight: 300;
  cursor: pointer;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.Main--Gallery .newdirectional i.lefticon {
  left: -10%;
}

.Main--Gallery .newdirectional i.righticon {
  right: -10%;
}

.modaal-gallery-next {
  margin-left: 0;
  right: -40px;
}

@media screen and (min-width: 40em) {
  .modaal-gallery-next {
    display: block !important;
    right: auto;
  }
}

.modaal-gallery-prev {
  margin-right: 0;
  left: -40px;
}

@media screen and (min-width: 40em) {
  .modaal-gallery-prev {
    display: block !important;
    left: auto;
  }
}

.modaal-gallery-control {
  height: 100%;
  border-radius: 0;
  width: 40px;
  bottom: 0px;
}

.modaal-gallery-control:hover, .modaal-gallery-control:focus {
  background-color: transparent;
}

.modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after, .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after {
  background-color: #eabfd3;
}

.modaal-gallery-control:before, .modaal-gallery-control:after {
  top: 50%;
  background-color: white;
}

@media screen and (min-width: 40em) {
  .modaal-gallery-control {
    width: 80px;
    bottom: 20px;
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    left: 40px;
  }
}

.modaal-image .modaal-inner-wrapper {
  padding-left: 60px;
  padding-right: 60px;
}

@media screen and (min-width: 40em) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.pleasewait {
  display: none;
  opacity: 0.95;
}

.pleasewait h2 {
  text-align: center;
  margin-top: 20%;
}

#pleasewait {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  padding-top: 20%;
}

#pleasewait p {
  color: #fefefe;
}

#pleasewait h2 {
  font-size: 3em;
  color: #fefefe;
}

.callout {
  background-color: #eabfd3;
  padding: 1rem 4rem;
}

.callout ul {
  font-size: 1.2rem;
  font-weight: 600;
}

.callout h4 {
  color: white;
  text-align: center;
}

.callout.alert ul li {
  color: #8C8E91;
}

h4.text-success {
  font-size: 2rem;
  padding: 1rem;
  margin: 1rem 0 0 0;
  color: green;
}

h4.text-danger {
  padding: 1rem;
  margin: 1rem 0 0 0;
  color: red;
  font-size: 2rem;
}

.relative {
  position: relative;
}

.np {
  padding: 0;
}

a#toTop {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: #eabfd3;
  color: white;
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 1rem;
  display: none;
  z-index: 3000;
  cursor: pointer;
}

#msgSubmit {
  color: green;
  text-align: center;
}

.u__white {
  color: white;
}

hr.pink-line {
  position: relative;
  display: inline-grid;
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background-color: #eabfd3;
  border: none;
  max-width: 100%;
}

.full {
  max-width: 100%;
}

.img-zoom {
  max-width: 100%;
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
}

.img-zoom:hover {
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 4.5s ease-out;
  -webkit-transition: all 4.5s ease-out;
}

.section-shadow, div.Home section#testimonials, div.Home section#instagram {
  -webkit-box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
  -moz-box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
  box-shadow: 0px 0px 12px 3px rgba(166, 168, 171, 0.5);
}

.gallery-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
}

form.generalForm h3 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
}

form.generalForm input {
  box-shadow: none;
  height: 2.75rem;
  width: 100%;
  font-family: "Open Sans", sans-serif;
}

form.generalForm input.danger {
  border-color: #cc4b37;
}

form.generalForm textarea {
  box-shadow: none;
  font-family: "Open Sans", sans-serif;
}

form.generalForm textarea.danger {
  border-color: #cc4b37;
}

form.generalForm span.mandatory {
  color: #eabfd3;
  font-size: 1rem;
  font-weight: 700;
}

form.generalForm button.sendButton {
  background: white;
  border: 1px solid transparent;
  font-family: "Halant", serif;
  font-size: 1.125rem;
  font-weight: 400;
  color: white;
  background-color: #eabfd3;
  transition: ease-in-out 0.5s;
}

form.generalForm button.sendButton:hover {
  background: white;
  border: 1px solid #eabfd3;
  color: #C5C5C5;
}

form.generalForm button.sendButton:focus, form.generalForm button.sendButton:active {
  outline: none;
}

div.contact ::-webkit-input-placeholder {
  color: #808080;
  font-weight: 400;
  font-family: "Halant", serif;
}

div.contact :-moz-placeholder {
  /* Firefox 18- */
  color: #808080;
  font-weight: 400;
  font-family: "Halant", serif;
}

div.contact ::-moz-placeholder {
  /* Firefox 19+ */
  color: #808080;
  font-weight: 400;
  font-family: "Halant", serif;
}

div.contact :-ms-input-placeholder {
  color: #808080;
  font-weight: 400;
  font-family: "Halant", serif;
}

div.contact form.contact-form {
  background-color: transparent;
  margin-bottom: 50px;
}

div.contact form.contact-form span.Line-Title {
  position: absolute;
  width: 100%;
  background-color: #eabfd3;
  height: 1px;
}

div.contact form.contact-form button.sendButton {
  position: relative;
  padding: 1rem 3.125rem;
  width: 100%;
  margin-top: 1rem;
}

div.contact form.contact-form textarea {
  margin-bottom: 0;
  height: 100%;
}

div.contact form.contact-form .g-recaptcha {
  transform: scale(0.7);
}

div.Home section#services .border-double {
  border-color: #eabfd3;
  border-style: solid;
  position: relative;
}

div.Home section#services .border-double > div {
  text-align: center;
  margin: 0;
  border-color: #eabfd3;
  border-style: solid;
  padding: 1.2rem 1rem;
}

div.Home section#services .border-double > div h3 {
  font-size: rem;
  text-transform: uppercase;
  margin: 0;
}

@media screen and (min-width: 40em) {
  div.Home section#services .border-double > div h3 {
    font-size: 2.375rem;
  }
}

div.Home section#services .border-double > div p {
  font-size: 0.875rem;
  line-height: 1.4;
}

div.Home section#services .border-double.top {
  padding: 10px 10px 0 10px;
  border-width: 2px 2px 0 2px;
}

div.Home section#services .border-double.top > div {
  border-width: 2px 2px 0 2px;
}

div.Home section#services .border-double.bottom {
  padding: 0 10px 10px 10px;
  border-width: 0 2px 2px 2px;
}

div.Home section#services .border-double.bottom > div {
  border-width: 0 2px 2px 2px;
}

div.Home section#services .service-box {
  position: relative;
  top: 0;
  width: 100%;
  height: 246px;
  transition: all 0.5s;
  outline: none;
  padding: 1rem;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

div.Home section#services .service-box .Text-Title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  border: 1px solid #ffffff;
  padding: 1rem;
}

div.Home section#services .service-box .Text-Title p {
  font-family: "Halant", serif;
  color: white;
  font-size: 1.5rem;
  margin-bottom: 0;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (min-width: 40em) {
  div.Home section#services .service-box .Text-Title p {
    font-size: 2rem;
  }
}

div.Home section#services .service-box.planning {
  height: 492px;
}

div.Home section#services .service-box:hover {
  background-size: 110%;
}

@media (max-width: 768px) {
  div.Home section#services .service-box {
    background-size: cover;
    height: 150px;
  }
  div.Home section#services .service-box.planning {
    height: 300px;
  }
}

@media (max-width: 1200px) {
  div.Home section#services .service-box {
    height: 180px;
  }
  div.Home section#services .service-box.planning {
    height: 360px;
  }
}

@media (min-width: 1200px) {
  div.Home section#services .service-box {
    height: 246px;
  }
  div.Home section#services .service-box.planning {
    height: 492px;
  }
}

div.Home section.Gallery {
  line-height: 0;
}

div.Home section.Gallery a {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;
  line-height: 0;
  text-align: center;
  overflow: hidden;
}

div.Home section.Gallery a .text {
  position: absolute;
  transform: translate3d(0, 0, -4px) scale(5);
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
}

div.Home section.Gallery a .text h4.gallery-name {
  font-size: 2.5rem;
  color: white;
  font-family: "Halant", serif;
  text-transform: uppercase;
}

@media screen and (min-width: 40em) {
  div.Home section.Gallery a .text h4.gallery-name {
    font-size: 4.5rem;
  }
}

div.Home section.Gallery a .text span.view-gallery {
  color: white;
  border: 1px solid white;
  padding: 0.5rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}

div.Home section#testimonials {
  background-image: url(/images/bg/backgroundTestimonials.jpg);
}

div.Home section#testimonials .Info-Test {
  position: relative;
  padding-top: 2rem;
  text-align: center;
}

div.Home section#testimonials .Info-Test hgroup {
  text-align: center;
  margin-bottom: 2rem;
}

div.Home section#testimonials .Info-Test hgroup h2 {
  font-family: "Halant", serif;
  font-size: 1.875rem;
  color: #A6A8AB;
  text-transform: uppercase;
  margin-bottom: 0;
}

div.Home section#testimonials .Info-Test hgroup h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  color: #eabfd3;
  text-transform: none;
  margin-bottom: 0;
}

div.Home section#testimonials .Info-Test p {
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  text-align: justify;
}

div.Home section#testimonials .Info-Test img.Symbol-Test {
  display: inline-block;
}

div.Home section#testimonials img.Testimonial--Image {
  margin: 0 auto;
}

div.Home section#about img.about-image {
  border: 1px solid #eabfd3;
  padding: 0.8rem;
  margin: 0 auto 2rem auto;
}

@media screen and (min-width: 40em) {
  div.Home section#about img.about-image {
    margin: 0;
  }
}

div.Home section#about h2 {
  text-align: center;
  margin-bottom: 0;
}

div.Home section#about p {
  font-family: "Open Sans", sans-serif;
  font-size: 0.8125rem;
}

div.Home section#team {
  background-image: url(/images/bg/backgroundTeam.jpg);
}

div.Home section#team h2.Header {
  color: white;
}

div.Home section#team .Person-Box {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: block;
  transition: all 0.7s;
  margin-bottom: 0 0 1rem 0;
  border: 10px solid #ffffff;
}

div.Home section#team .Person-Box img.Person {
  width: 100%;
  height: 100%;
  transition: all 0.7s;
}

div.Home section#team .Person-Box .Tpt-Border {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.875rem;
  background-color: transparent;
  transition: all 0.7s;
}

@media screen and (min-width: 40em) {
  div.Home section#team .Person-Box .Tpt-Border {
    padding: 1.25rem;
  }
}

div.Home section#team .Person-Box .Tpt-Border .Info-Team {
  display: none;
  position: relative;
  height: 20%;
  top: 80%;
  width: 100%;
  padding: 0.5rem;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 0.7s;
}

@media screen and (min-width: 40em) {
  div.Home section#team .Person-Box .Tpt-Border .Info-Team {
    padding: 2rem;
  }
}

div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws, div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws-Footer {
  margin: 0;
  font-size: 0.8125rem;
  line-height: 0.2;
}

div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws hr.pink-line, div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws-Footer hr.pink-line {
  margin: 10px 0;
}

div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws small, div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws-Footer small {
  font-family: "Open Sans", sans-serif;
  font-size: 0.5rem;
  text-transform: lowercase;
}

@media screen and (min-width: 40em) {
  div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws, div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws-Footer {
    margin-bottom: 1.8rem;
    font-size: 1.875rem;
  }
  div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws small, div.Home section#team .Person-Box .Tpt-Border .Info-Team span.Cws-Footer small {
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

div.Home section#team .Person-Box .Tpt-Border .Info-Team p {
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
}

@media screen and (min-width: 40em) {
  div.Home section#team .Person-Box .Tpt-Border .Info-Team p {
    font-size: 0.75rem;
  }
}

@media print, screen and (min-width: 64em) {
  div.Home section#team .Person-Box .Tpt-Border .Info-Team {
    display: block;
  }
}

div.Home section#team .Person-Box .Tpt-Border .Info-Team-Responsive {
  position: relative;
  width: 100%;
  height: 100%;
}

div.Home section#team .Person-Box .Tpt-Border .Info-Team-Responsive a.btn-Responsive {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  font-family: "Halant", serif;
  font-size: 1.3rem;
  color: #A6A8AB;
  text-transform: uppercase;
  text-align: center;
  padding: 1.5rem 0.8rem 0.8rem 0.8rem;
  background-color: rgba(255, 255, 255, 0.8);
  line-height: 0.7;
  outline: none;
}

div.Home section#team .Person-Box .Tpt-Border .Info-Team-Responsive a.btn-Responsive span {
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  text-transform: capitalize;
  color: #eabfd3;
}

@media print, screen and (min-width: 64em) {
  div.Home section#team .Person-Box .Tpt-Border .Info-Team-Responsive {
    display: none;
  }
  div.Home section#team .Person-Box .Tpt-Border .Info-Team-Responsive a.btn-Responsive {
    display: none;
  }
}

div.Home section#team .Person-Box .Tpt-Border a.Contact-Team {
  position: absolute;
  padding: 1rem;
  left: 35%;
  transform: translatex(-50%);
  color: #A6A8AB;
  background-color: transparent;
  border-right: 1px solid #eabfd3;
  font-size: 1.1725rem;
  transition: all 0.5s;
}

div.Home section#team .Person-Box .Tpt-Border a.Contact-Team:hover {
  border-right: 1px solid #A6A8AB;
  color: #eabfd3;
}

div.Home section#team .Person-Box .Tpt-Border ul.Social-Header {
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);
}

div.Home section#team .Person-Box .Tpt-Border ul.Social-Header li a {
  color: #eabfd3;
  font-size: 0.8125rem;
}

@media screen and (min-width: 40em) {
  div.Home section#team .Person-Box .Tpt-Border ul.Social-Header li a {
    font-size: 1.5rem;
  }
}

div.Home section#team .Person-Box:hover img.Person {
  filter: blur(5px);
}

div.Home section#team .Person-Box:hover .Tpt-Border .Info-Team {
  height: 100%;
  top: 0;
}

div.Home section#blog .article-container .img-link img {
  width: 100%;
}

div.Home section#blog .article-container a.post-name h6 {
  font-size: 1.4rem;
  color: #A6A8AB;
  text-align: left;
  margin: 1rem 0 0 0;
  line-height: 1;
}

div.Home section#blog .article-container hr.title-break {
  display: block;
  width: 100%;
  background-color: #eabfd3;
  height: 1px;
  margin: 1rem 0;
}

div.Home section#blog .article-container p.post-detail {
  font-size: 0.875rem;
}

div.Home section#blog .article-container a.continue {
  color: #eabfd3;
  border: none;
  background: transparent;
  text-align: center;
  width: 100%;
  line-height: 0.7;
}

div.Home section#blog .article-container a.continue:after {
  content: '-';
  display: block;
  font-size: 2rem;
  color: #eabfd3;
}

div.Home section#blog .article-container .Send:hover {
  color: #C5C5C5;
}

div.Home section#blog div.lines-button {
  background-color: transparent;
  border-color: #eabfd3;
  border-width: 2px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  padding: 5px;
  width: 100%;
  position: inherit;
  top: 40%;
}

div.Home section#instagram .Instagram-Container {
  margin: 0;
  max-width: 100%;
  width: 100%;
  padding: 5px 0;
  box-shadow: 4px 0 4px 0px #A6A8AB;
}

div.Home section#contact h5 {
  font-family: "Halant", serif;
  font-size: 1.25rem;
  color: #C5C5C5;
  margin-bottom: 0;
}

div.Home section#contact span {
  width: 100%;
  background-color: #eabfd3;
  display: block;
  height: 1px;
  margin-bottom: 1rem;
}

div.Home section#contact .Info-Contact {
  padding-left: 0;
}

div.Home section#contact .Info-Contact p {
  font-family: "Open Sans", sans-serif;
  font-size: 0.9375rem;
  text-align: left;
  line-height: 1.5;
}

div.Home section#contact .Info-Contact p.Place {
  font-size: 0.8125rem;
}

div.Home section#contact .Info-Contact a.Phone,
div.Home section#contact .Info-Contact a.Email {
  color: #A6A8AB;
  line-height: 1.5;
}

div.Home section#contact .Info-Contact a.Phone:hover,
div.Home section#contact .Info-Contact a.Email:hover {
  color: #eabfd3;
}

div.Home section#contact .Info-Contact ul.Social-Header li {
  float: left;
}

div.Home section#contact .Info-Contact ul.Social-Header li a {
  margin-left: 0;
  margin-right: 1.3rem;
}

@media screen and (min-width: 40em) {
  div.Home section#contact .Info-Contact {
    padding-left: 2rem;
  }
}

div.Home section#contact #MainMap {
  height: 450px;
  width: 100%;
}

hr.separator {
  border: none;
  margin: 1rem;
  box-shadow: none;
  width: 100%;
  display: inline-block;
  clear: both;
}

section.Contact-thankyou h1 {
  font-family: "Halant", serif;
  font-size: 2.5rem;
  color: #C5C5C5;
  margin: 1rem auto;
}

section.Contact-thankyou h5 {
  font-family: "Halant", serif;
  font-size: 1.25rem;
  color: #C5C5C5;
  margin-bottom: 6px;
}

section.Contact-thankyou .Info-Contact {
  padding-left: 0;
}

section.Contact-thankyou .Info-Contact span {
  width: 100%;
  background-color: #eabfd3;
  display: block;
  height: 1px;
  margin-bottom: 1rem;
}

section.Contact-thankyou .Info-Contact p {
  font-family: "Open Sans", sans-serif;
  font-size: 0.9375rem;
  text-align: left;
  line-height: 1.5;
}

section.Contact-thankyou .Info-Contact p.Place {
  font-size: 0.8125rem;
}

section.Contact-thankyou .Info-Contact a.Phone,
section.Contact-thankyou .Info-Contact a.Email {
  color: #A6A8AB;
  line-height: 1.5;
}

section.Contact-thankyou .Info-Contact a.Phone:hover,
section.Contact-thankyou .Info-Contact a.Email:hover {
  color: #eabfd3;
}

section.Contact-thankyou .Info-Contact ul.Social-Header li {
  float: left;
}

section.Contact-thankyou .Info-Contact ul.Social-Header li a {
  margin-left: 0;
  margin-right: 1.3rem;
}

/*
|--------------------------------------------------------------------------
| Slider
|--------------------------------------------------------------------------
*/
#Slider-Testimonials {
  margin-bottom: 0;
}

#Slider-Testimonials .slick-dots {
  bottom: 0;
  margin: 10px 0 1rem 0;
  position: relative;
}

#Slider-Testimonials .slick-dots li button {
  background: #C5C5C5;
  opacity: 0.2;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

#Slider-Testimonials .slick-dots li button:before {
  color: transparent;
}

#Slider-Testimonials .slick-dots li.slick-active button {
  background-color: #eabfd3;
  opacity: 1;
}

#Slider-Testimonials .slick-prev,
#Slider-Testimonials .slick-next {
  height: 100%;
  width: 36px;
  z-index: 1000;
}

#Slider-Testimonials .slick-prev:before,
#Slider-Testimonials .slick-next:before {
  transition: all 0.7s;
  content: '';
  font-size: 90px;
  color: #eabfd3;
  font-family: 'FontAwesome';
  position: absolute;
  top: 30%;
  z-index: 1000;
  height: auto;
  width: 40px;
}

@media (max-width: 639px) {
  #Slider-Testimonials .slick-prev,
  #Slider-Testimonials .slick-next {
    display: none;
  }
}

#Slider-Testimonials .slick-prev {
  left: -80px;
}

#Slider-Testimonials .slick-prev:before {
  content: "\f104";
  left: 0;
}

#Slider-Testimonials .slick-next {
  right: -70px;
}

#Slider-Testimonials .slick-next:before {
  content: "\f105";
  right: 0;
}

.Top-Footer {
  position: relative;
  background-color: #A6A8AB;
  border-bottom: 1px solid #eabfd3;
  height: 0.4rem;
  margin: 0;
}

footer {
  position: relative;
  background-color: #868686;
  text-align: center;
  padding: 4rem 0;
}

footer p {
  text-align: center;
  line-height: 1;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 40em) {
  footer a.Send {
    margin: 0;
  }
  footer p {
    text-align: right;
  }
}

footer a.footer-link {
  width: 100%;
}

section.Footer-Copy {
  background-color: #6D6E71;
  padding: 1rem 5rem;
  text-align: center;
  overflow: hidden;
}

section.Footer-Copy .content-left {
  float: none;
}

section.Footer-Copy .content-right {
  float: none;
}

section.Footer-Copy p {
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  color: #C5C5C5;
  margin: 0 0 1rem 0;
  text-align: center;
  display: block;
}

@media screen and (min-width: 40em) {
  section.Footer-Copy p {
    display: inline-block;
    margin-bottom: 0;
  }
}

section.Footer-Copy p.block {
  display: block;
  margin: 1rem 0;
  text-align: center;
}

section.Footer-Copy a {
  display: inline-block;
  margin-left: 1rem;
}

section.Footer-Copy a.sitemap {
  font-weight: 300;
  color: #C5C5C5;
}

@media screen and (min-width: 40em) {
  section.Footer-Copy .content-left {
    float: left;
  }
  section.Footer-Copy .content-right {
    float: right;
  }
  section.Footer-Copy a {
    margin-left: 2rem;
  }
}

/*
|--------------------------------------------------------------------------
|  Inquire Modal
|--------------------------------------------------------------------------
*/
.Inquire__Modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: scroll;
}

.Inquire__Modal button.closeme {
  border-radius: 50%;
  background: #eabfd3;
  color: white;
  position: absolute;
  right: 1rem;
  top: 2.2rem;
  width: 60px;
  height: 60px;
  z-index: 1000;
}

@media print, screen and (min-width: 64em) {
  .Inquire__Modal button.closeme {
    right: 8rem;
  }
}

.m-4 {
  margin: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-small-4 {
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

form.Inquire__Form {
  border: 1px solid rgba(0, 0, 0, 0.7);
  background-color: white;
  padding: 2rem;
  margin-top: 2rem;
  position: relative;
  display: inline-block;
  height: 100%;
  overflow: scroll;
  z-index: 900;
}

form.Inquire__Form h3 {
  color: #8C8E91;
}

form.Inquire__Form h4 {
  color: #8C8E91;
}

form.Inquire__Form label {
  color: #8C8E91;
}

form.Inquire__Form input,
form.Inquire__Form textarea {
  box-shadow: none;
  border-color: #808080;
}
