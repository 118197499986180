.parallax {
	height: 320px;
	background: $white;
	position: relative;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	background-attachment: fixed;
	-webkit-perspective: 1px;
	perspective: 1px;

	@media only screen and (max-device-width: 1024px) {
	    background-attachment: scroll;	 
	}
}

.flowers {
	background-image: url(/images/gallery/bg-flowers.jpg);
}

.cakes {
	background-image: url(/images/gallery/bg-cakes.jpg);
}

.music {
	background-image: url(/images/gallery/bg-music.jpg);
}

.venues {
	background-image: url(/images/gallery/main-venues.jpg);
}

.details {
	background-image: url(/images/gallery/main-details.jpg);
}

.couples {
	background-image: url(/images/gallery/bg-couples.jpg);
}