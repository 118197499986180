/*
|--------------------------------------------------------------------------
|	Vendor Imports
|--------------------------------------------------------------------------
*/

[v-cloak] {
	display: none;
}

@import "components/app";
@import "vendor/bower_components/slick-carousel/slick/slick.scss";
@import "vendor/bower_components/slick-carousel/slick/slick-theme.scss";
@import "vendor/bower_components/modaal/dist/css/modaal.scss";
@import "elements/colors";
@import "elements/fonts";
@import "elements/general";
@import "elements/buttons";
@import "elements/_social-buttons";
@import "components/header";
@import "elements/home-slider";
@import "elements/utilities";
@import "elements/parallax";
@import "elements/utilities";
@import "elements/forms";
@import "elements/sections";
@import "elements/testimonials";
@import "components/footer";
/*
|--------------------------------------------------------------------------
|  Inquire Modal
|--------------------------------------------------------------------------
*/

.Inquire__Modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	overflow: scroll;
	button.closeme {
		border-radius: 50%;
		background: $pink;
		color: white;
		position: absolute;
		right: 1rem;
		top: 2.2rem;
		width: 60px;
		height: 60px;
		z-index: 1000;
		@include breakpoint(large) {
			right: 8rem;
		}
	}
}

.m-4 {
	margin: 1rem;
}

.mt-2 {
	margin-top: 0.5rem;
}

.mt-4 {
	margin-top: 1rem;
}

.mb-4 {
	margin-bottom: 1rem;
}

.mb-small-4 {
	margin-bottom: 1rem;
}

.my-4 {
	margin-top: 1rem;
	margin-bottom: 1rem
}

.my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem
}

.mb-0 {
	margin-bottom: 0 !important;
}

form.Inquire__Form {
	border: 1px solid rgba(0, 0, 0, 0.7);
	background-color: white;
	padding: 2rem;
	margin-top: 2rem;
	position: relative;
	display: inline-block;
	height: 100%;
	overflow: scroll;
	z-index: 900;
	h3 {
		color: $gray-text;
	}
	h4 {
		color: $gray-text;
	}
	label {
		color: $gray-text;
	}
	input,
	textarea {
		box-shadow: none;
		border-color: $gray-dark;
	}
}
