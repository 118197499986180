/*
|--------------------------------------------------------------------------
| Slider
|--------------------------------------------------------------------------
*/
#Slider-Testimonials {
	margin-bottom: 0;
	.slick-dots {
		bottom: 0;
		margin: 10px 0 1rem 0;
		position: relative;
		li {
			button {
				background: $gray;
				opacity: 0.2;
				border-radius: 50%;
				width: 16px;
				height: 16px;
                &:before {
                    color: transparent;
                }
			}
			&.slick-active button {
				background-color: $pink;
				opacity: 1;
			}
		}
	}
	.slick-prev,
	.slick-next {
        height: 100%;
        width: 36px;
        z-index: 1000;
        &:before {
            transition: all 0.7s;
            content: '';
			font-size: 90px;
			color: $pink;
    		font-family: 'FontAwesome';
    		position: absolute;
            top: 30%;
            z-index: 1000;
            height: auto;
            width: 40px;
        }

        @media (max-width: 639px) {
            display: none;
        }
	}
	.slick-prev {
        left: -80px;
    	&:before {
        	content: "\f104";
            left: 0;
    	}
	}
	.slick-next {
        right: -70px;
    	&:before {
        	content: "\f105";
            right: 0;
    	}
	}
}
