form.generalForm {
	h3 {
		font-size: 1.5rem;
		text-align: center;
		font-weight: 400;
	}
	input {
		box-shadow: none;
		height: 2.75rem;
		width: 100%;
		font-family: $font-open;
		&.danger {
			border-color: #cc4b37;
		}
	}
	textarea {
		box-shadow: none;
		font-family: $font-open;
		&.danger {
			border-color: #cc4b37;
		}
	}
	span.mandatory {
		color: $pink;
		font-size: 1rem;
		font-weight: 700;
	}
	button.sendButton {
		background: white;
		border: 1px solid transparent;
		font-family: $font-halant;
		font-size: 1.125rem;
		font-weight: 400;
		color: white;
		background-color: $pink;
		transition: ease-in-out 0.5s;
		&:hover {
			background: white;
			border: 1px solid $pink;
			color: $gray;
		}
		&:focus,
		&:active {
			outline: none;
		}
	}
}

div.contact {
	::-webkit-input-placeholder {
	   color: $gray-dark;
	   font-weight: 400;
	   font-family: $font-halant;
	}
	:-moz-placeholder { /* Firefox 18- */
	   color: $gray-dark;
	   font-weight: 400;
	   font-family: $font-halant;
	}
	::-moz-placeholder {  /* Firefox 19+ */
	   color: $gray-dark;
	   font-weight: 400;
	   font-family: $font-halant;
	}
	:-ms-input-placeholder {
	   color: $gray-dark;
	   font-weight: 400;
	   font-family: $font-halant;
	}
	form.contact-form {
			background-color: transparent;
			margin-bottom: 50px;
		span.Line-Title {
			position: absolute;
			width: 100%;
			background-color: $pink;
			height: 1px;
		}
		button.sendButton {
			position: relative;
			padding: 1rem 3.125rem;
    		width: 100%;
    		margin-top: 1rem;
		}
		textarea {
			margin-bottom: 0;
			height: 100%
		}
		.g-recaptcha {
			transform: scale(0.7);
		}
	}
}
